import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Box, Button, Divider } from "@mui/material";
import { useRevampWorkplaceSigningFlow } from "@src/appV2/Shifts/Shift/useRevampWorkplaceSigningFlow";

import { WorkplaceFeedbackData } from "./types";

type WorkplaceFeedbackQuestionProps = {
  onFeedbackDataUpdate: (data: WorkplaceFeedbackData) => void;
  facilityId: string;
  feedbackData?: WorkplaceFeedbackData;
  showError: boolean;
  setShowError: (showError: boolean) => void;
};

export function WorkplaceFeedbackQuestion(props: WorkplaceFeedbackQuestionProps) {
  const { onFeedbackDataUpdate, feedbackData, facilityId, showError, setShowError } = props;
  const { firstQuestion, mainQuestion } = useRevampWorkplaceSigningFlow(facilityId);
  const { firstResponse, secondResponse } = feedbackData ?? {};

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      {/* Task completion */}
      {isDefined(firstQuestion.description) && (
        <>
          <Box aria-label="First Feedback Question">
            <Text mb={1}>{firstQuestion.description}</Text>
            <Box display="flex" gap={1}>
              {["Yes", "No"].map((label) => (
                <Button
                  key={label}
                  variant={firstResponse === label ? "contained" : "outlined"}
                  color="primary"
                  fullWidth
                  onClick={() => {
                    setShowError(false);
                    onFeedbackDataUpdate({
                      firstResponse: label,
                    });
                  }}
                  size="small"
                  sx={{ px: 0 }}
                >
                  {label}
                </Button>
              ))}
            </Box>
          </Box>
          <Divider />
        </>
      )}

      {/* Performance assessment */}
      <Box>
        <Text mb={1} variant="subtitle1">
          {mainQuestion.description}
        </Text>
        <Box display="flex" flexDirection="column" gap={1}>
          {mainQuestion.options.map(({ value, type }) => (
            <Button
              key={value}
              variant={secondResponse === value ? "contained" : "outlined"}
              color="primary"
              fullWidth
              onClick={() => {
                setShowError(false);
                onFeedbackDataUpdate({
                  secondResponse: value,
                  secondResponseType: type,
                });
              }}
              size="small"
            >
              {value}
            </Button>
          ))}
        </Box>
      </Box>

      {showError && (
        <Text color="error" mt={1} variant="body2">
          *Please select an option
        </Text>
      )}

      <Text variant="body2" color={(theme) => theme.palette.grey[600]}>
        Your response is anonymous and shared with your workplace administrators only.
      </Text>
    </Box>
  );
}
